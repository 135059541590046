<template>
  <div class="forms">
    <div class="back" :style="backStyle" @click="$router.push('/')">
      <i class="el-icon-back"></i>
    </div>
    <div :style="titleStyle" style="color: #0062e2;">
      {{ title }}
    </div>
    <el-form
      :style="updateFormStyle"
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-form-item prop="username" :style="formItemStyle">
        <el-input
          :style="inputStyle"
          prefix-icon="el-icon-user"
          v-model="ruleForm.username"
          :placeholder="form.username.rule1"
        ></el-input>
      </el-form-item>
      <el-form-item prop="pass" :style="formItemStyle">
        <el-input
          :style="inputStyle"
          type="password"
          prefix-icon="el-icon-lock"
          show-password
          :placeholder="form.pass.rule1"
          v-model="ruleForm.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item prop="checkPass" :style="formItemStyle">
        <el-input
          :style="inputStyle"
          type="password"
          prefix-icon="el-icon-lock"
          show-password
          :placeholder="form.newPass.rule1"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item :style="formItemStyle">
        <el-button
          :style="buttonStyle"
          type="primary"
          @click="submitForm('ruleForm')"
          style="width: 100%; height: 100%; border-radius: 50px;"
        >
          确定
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { Form, FormItem, Button, Input, PageHeader } from 'element-ui'
  import { TITLE, FORM } from '../config'
  import { UpdatePass } from '../api'
  import md5 from 'md5'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Button.name]: Button,
      [Input.name]: Input,
      [PageHeader.name]: PageHeader
    },

    computed: {
      ...mapGetters([
        'titleStyle',
        'formItemStyle',
        'inputStyle',
        'buttonStyle',
        'backStyle',
        'updateFormStyle'
      ])
    },

    data() {
      /** 校验用户名 */
      var checkUsername = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.form.username.rule1))
        }

        if (value.toString().length < this.form.username.min) {
          return callback(new Error(this.form.username.rule2))
        }

        if (value.toString().length > this.form.username.max) {
          return callback(new Error(this.form.username.rule3))
        }

        callback()
      }

      /** 校验旧密码 */
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.form.pass.rule1))
        }

        if (value.toString().length < this.form.pass.min) {
          return callback(new Error(this.form.pass.rule2))
        }

        if (value.toString().length > this.form.pass.max) {
          return callback(new Error(this.form.pass.rule3))
        }

        callback()
      }

      /** 校验新密码 */
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.form.newPass.rule1))
        }

        if (value.toString().length < this.form.newPass.min) {
          return callback(new Error(this.form.newPass.rule2))
        }

        if (value.toString().length > this.form.newPass.max) {
          return callback(new Error(this.form.newPass.rule3))
        }

        callback()
      }

      return {
        title: TITLE,
        form: FORM,
        ruleForm: {
          username: '',
          pass: '',
          checkPass: ''
        },
        rules: {
          pass: [{ validator: validatePass, trigger: 'blur' }],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' },
            {
              required: true,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/,
              message: FORM.newPass.rule4,
              trigger: 'blur'
            }
          ],
          username: [{ validator: checkUsername, trigger: 'blur' }]
        },
        isPc: false
      }
    },

    methods: {
      /** 提交 */
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.updatePass()
          }
        })
      },

      /** 修改密码 */
      async updatePass() {
        const { code, message } = await UpdatePass({
          userName: this.ruleForm.username,
          password: md5(this.ruleForm.pass),
          newPassword: this.ruleForm.checkPass
        })

        if (code !== 0) {
          this.$message.error(message)
        } else {
          this.$message.success({
            message: '修改成功',
            duration: 2000,
            onClose: () => {
              this.$router.push('/')
            }
          })
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .update-password {
    cursor: pointer;
  }

  .forms {
    width: 90%;
    height: 90%;
    padding: 5% 5%;
    background: #fff;
    position: relative;
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    color: #0062e2;
  }

  /deep/ .el-form-item__content {
    height: 100%;
  }

  /deep/ .el-input__inner {
    height: 100%;
  }
</style>
